#adminSitePanel {
  .container {
    margin: 1rem auto;
  }
  .tableContainer {
    position: relative;
    .filter {
      position: absolute;
      top: 15px;
      z-index: 2;
      left: 50%;
      right: 50%;
    }
    .filter > div {
      margin-top: 0 !important;
    }
  }
  .monthlyReportTable {
    td {
      border-bottom: 1px solid;
    }
  }
  .monthlyReportTable > div {
    box-shadow: none !important;
  }
}
