.inputField {
  padding: 0.4rem 0.5rem;
  border: 1px solid #cecece;
  border-radius: 5px;
  outline: red;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  font-size: 1rem;

  &:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }
}
