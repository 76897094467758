.masterListContainer {
  width: 100%;
  padding: 1rem 0;
  z-index: -1;
  .masterList {
    background-color: #f6f6f6;
    box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
    padding: 1rem 0.9rem;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;

    .formTitle {
      margin: 0.2rem 0;
    }
    .formDetails {
      font-size: 0.85rem;
      font-weight: 400;
      margin: 1rem 3rem;
    }
    .table {
      border: 2px solid black;
      width: 100%;
      overflow-y: auto;
      .tableBody {
        .tableTop {
          .tableTopContainer {
            display: flex;
            justify-content: space-between;
            padding: 0.5rem;
            .searchContainer {
              display: flex;
              align-items: center;
              button {
                margin-left: 0.5rem;
              }
            }
            .actionContainer {
              display: flex;
              align-items: center;
              a {
                margin-right: 0.5rem;
                white-space: nowrap;
              }
            }
          }
        }

        .labels > th,
        .labels2 > th {
          text-align: center;
        }

        .masterListData > td {
          text-align: center;
          .actionContainer {
            button {
              margin-bottom: 0.5rem;
            }
            &:first-child {
              margin-top: 0.5rem;
            }
          }
        }
      }

      .tableFooter {
        border: 3px solid black;
      }
    }
  }
}

.text-center {
  text-align: center;
}

table,
th,
td {
  border: 1px solid black;
  border-spacing: 0;
  border-collapse: collapse;
}

th,
td {
  text-align: start;
  padding: 0.2rem 0.5rem;
  overflow: hidden;
}

.rotateText {
  transform: rotate(270deg);
  margin: 0;
}
