.notificationContainer {
  position: fixed;
  top: 15vh;
  right: 0;
  width: 220px;
  background-color: rgb(255, 255, 255);
  padding: 0.5rem;
  padding-right: 1rem;
  align-items: center;
  justify-content: space-between;
  border-left: 2px solid transparent;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  z-index: 999;
  box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 15%);
  .notificationMessage {
    white-space: nowrap;
  }
}

.visible {
  transform: translateX(0%);
}
