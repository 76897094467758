.formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  margin-bottom: 2rem;
  .driverForm {
    min-width: 900px;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.15);
    padding: 1rem 2rem;
    border-radius: 10px;
    background: #ffffff85;
    .twoFields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
    button {
      margin-top: 0.5rem;
      width: 200px;
    }

    .signature {
      display: flex;
      flex-direction: column;
      max-width: fit-content;
      align-items: flex-start;

      label {
        font-size: 0.9rem;
      }
      .signatureContainer {
        box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
        margin: 0.5rem 0;
        box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
        padding: 3rem 0.5rem;
        img {
          width: 400px;
        }
      }
      button {
        margin-left: 0;
      }
    }
  }

  .driverFormPage > div {
    margin-top: 0.6rem;
  }
}
