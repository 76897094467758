#adminVendorPanel {
  .container {
    margin: 1rem auto;
  }

  th,
  td {
    text-align: center;
  }
  th {
    background-color: azure;
  }
  td {
    background-color: white;
  }
  .tableContainer {
    width: 100%;
    table {
      width: 100%;

      .tableHeader {
        .headerContainer {
          display: flex;
          justify-content: flex-end;
          button {
            width: 150px;
          }
        }
      }

      .driverTableHead > th {
        text-align: center;
      }
      .driverData > td {
        text-align: center;
      }
    }
  }
}
