#vendorDriver {
  .container {
    margin: 1rem auto;
  }

  .tableContainer {
    width: 100%;
    table {
      width: 100%;
      .filterContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: left;

        .site {
          div {
            margin-top: 0 !important;
            width: 400px;
          }
        }
      }
      .filterContainer > div {
        padding: 0.5rem;
        display: flex;
        align-items: center;
        b {
          margin-right: 0.5rem;
        }
      }
    }

    th,
    td {
      text-align: center;
    }
    th {
      background-color: rgb(235, 235, 235);
    }
    td {
      background-color: rgb(255, 255, 255);
    }
  }
}
