header {
  display: flex;
  padding: 1rem 0;
  min-height: 10vh;
  background-color: #fff;
  box-shadow: 1px 6px 8px -5px rgb(0 0 0 / 30%);
  z-index: 1000;
  .container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .headerLeft {
    .logo {
      font-size: 1.5rem;
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    div {
      margin-left: 2.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .user {
      display: inline-flex;
      align-items: center;
      position: relative;

      .userImg {
        width: 35px;
        height: 35px;
        border: none;
        border-radius: 50%;
      }

      .userName {
        width: 120px;
        background-color: rgb(235, 235, 235);
        margin-left: 1rem;
        padding: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: grey;
        .dropdownicon {
          cursor: pointer;
          margin-left: 0.5rem;
        }
      }

      .userDropDown {
        background-color: #ebebeb;
        position: absolute;
        top: 5.8vh;
        width: 120px;
        padding: 0.4rem;
        left: 0;
        overflow: hidden;
        max-height: 0px;
        display: none;
        transition: all 0.3s ease-in-out;
        z-index: 999;

        ul {
          padding: 0;
          margin: 0;
          width: 100%;
        }
        li {
          list-style: none;
          color: black;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid grey;
          padding: 0.4rem;
          svg {
            margin-right: 0.5rem;
          }
          &:last-child {
            border: 0;
          }
        }
      }
      .drop {
        display: block !important;
        max-height: 155px;
      }
    }
  }
}
