.formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  .siteForm {
    min-width: 350px;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    border-radius: 10px;
    background: #ffffff85;
    button {
      margin-top: 0.5rem;
      width: 200px;
    }
  }

  .createStudentForm > div {
    margin-top: 0.6rem;
  }
}
