#SiteCreateOrder {
  .formContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .createOrderForm {
      min-width: 700px;
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.15);
      padding: 0.5rem;
      border-radius: 10px;
      background: #ffffff85;

      .vendor {
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
        .vendorName {
          padding: 0rem 0.2rem;
          font-weight: bold;
        }
      }
      button {
        margin-top: 0.5rem;
        width: 150px;
      }
    }
  }
}
