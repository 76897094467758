#enrollmentForm {
  .checked,
  .unchecked {
    width: 20px !important;
  }
  table {
    width: 100%;
    border: 2px solid #000;
  }
  .tickImg {
    width: 15px;
  }
  .row-center {
    th {
      text-align: center;
    }
    td {
      text-align: center;
    }
  }

  .text-center {
    text-align: center;
  }
  .enrollmentFormContainer {
    width: 100%;
    padding: 1rem 0;
    z-index: -1;
    overflow-x: auto;
    .actions {
      margin: 0 0.9rem;
      margin-top: 1rem;
      display: flex;
      align-items: center;

      button {
        width: 200px;
        margin: 0;
        margin-right: 0.5rem;
      }
    }
    .enrollmentForm {
      background-color: #f6f6f6;
      box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
      padding: 1rem 0.9rem;
      margin: 0 0.9rem;
      .enrollmentFormHeader {
        text-align: center;
        .description {
          font-size: 0.9rem;
        }
      }
      .headerBox {
        border: 2px solid #000;

        .instructions {
          padding: 0 0.5rem;
          h2 {
            font-size: 1rem;
          }
          ul {
            li {
              font-size: 0.9rem;
            }
          }
        }
        .centerName {
          padding: 0 0.5rem;
          border-top: 1px solid #000;
          border-bottom: 1px solid #000;
        }
        .studentDetails {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .studentName {
            padding: 0 0.5rem;
            width: 50%;
            display: flex;
            align-items: center;
            span {
              padding-left: 0.5rem;
            }
          }
          .age {
            padding: 0 0.5rem;
            width: 20%;
            display: flex;
            align-items: center;
            span {
              padding-left: 0.5rem;
            }
          }
          .birthDate {
            padding: 0 0.5rem;
            width: 30%;
            display: flex;
            align-items: center;
            span {
              padding-left: 0.5rem;
            }
          }
        }
      }
      .mealCheckBox {
        margin-top: 1rem;
        tbody {
          .changedtick {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .daysRow {
            tr,
            td {
              text-align: center;
            }
          }
        }
      }
      .parentInfoBox {
        margin-top: 1rem;
        border: 2px solid #000;
        .firstRow {
          display: flex;
          text-align: center;
          justify-content: space-between;
          border-bottom: 2px solid #000;
          padding: 0.5rem;
          .signature {
            text-align: left;
            width: 40%;
          }
          .date {
            width: 30%;
            text-align: left;
          }
          .phoneNumber {
            width: 30%;
            text-align: left;
          }
        }
        .secondRow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.5rem;
          border-bottom: 2px solid #000;
          .address {
            text-align: left;
            width: 100%;
          }
        }
        .thirRow {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.5rem;
          border-bottom: 2px solid #000;
          .street {
            width: 40%;
            text-align: left;
          }
          .city {
            width: 30%;
            text-align: left;
          }
          .zipCode {
            width: 30%;
            text-align: left;
          }
        }
        .rights {
          padding: 0.5rem;
        }
      }
    }
  }
}
