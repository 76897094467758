.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  position: relative;
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000048;
    z-index: 222;
    img {
      width: 70px;
      height: 70px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .dashboardHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 12vh;
    position: relative;
    .backwardLink {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.7;
      }
    }
    .title {
      font-size: 2rem;
      color: #636363;
      font-weight: 400;
      text-transform: capitalize;
    }
    .operationName {
      margin: 0;
      font-weight: 400;
    }
  }

  .children {
    margin: auto 0;
  }
}
