#dailySlipPage {
  .container {
    margin: 1rem auto;
  }

  .tableContainer {
    width: 100%;
    table {
      width: 100%;

      .header {
        .headerContainer {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  th,
  td {
    text-align: center;
    background-color: white;
  }
}
