.menuContainer {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: 1rem 0;
  z-index: -1;

  .breakfast {
    background-color: #d03161;
    color: #fff;
  }
  .amSnack {
    background-color: #dce4e5;
  }
  .lunch {
    background-color: #bfd8d1;
  }
  .pmSnack {
    background-color: #178a94;
    color: #fff;
  }
  .supper {
    background-color: #2b374b;
    color: #fff;
  }
  .eveningSnack {
    background-color: #54696a;
    color: #fff;
  }
  .menu {
    background-color: #f6f6f6;
    box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
    padding: 1rem 0.9rem;
    display: flex;
    flex-direction: column;

    .formTitle {
      margin: 0.5rem 0;
      margin-bottom: 1rem;
    }

    .table {
      border: 3px solid #000;
      width: 100%;
      text-align: left;
      position: relative;
      border-collapse: collapse;

      .tableBody {
        .labels2 > th > div {
          font-size: 0.8rem;
          font-weight: 400;
        }
        .labels {
          border-top: 3px solid #000;
        }
        tr > th {
          text-align: center;
          padding: 0.2rem;
        }
        tr > td {
          text-align: center;
          pre {
            font-size: 1rem;
          }
        }
        tr > td > div {
          width: 100%;
          margin: 0 auto;
          align-items: center;
          .txtarea {
            min-height: 20px;
            width: 200px;
          }
        }
      }
    }
  }

  th,
  td {
    text-align: start;
    padding: 1.3rem 1.5rem;
    overflow: hidden;
  }

  .rotateText {
    transform: rotate(270deg);
    margin: 0;
    white-space: nowrap;
    font-weight: 600;
  }
}

.text-center {
  text-align: center;
}

table,
th,
td {
  border: 1px solid black;
  border-spacing: 0;
  border-collapse: collapse;
}
