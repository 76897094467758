.signatureFieldContainer {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 !important;
  .sigantureFieldContainerhead {
    label {
      font-weight: 600;
      font-size: 0.9rem;
    }
    button {
      margin: 0 0.5rem;
      max-width: min-content;
      padding: 0.3rem 0.9rem;
      border: 1px solid #fff;
    }
  }
  .signatureField {
    margin: 0.5rem 0;
    box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
    max-width: fit-content;
    border: 1px solid #cecece;
  }
}
