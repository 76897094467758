#deliverySlip {
  .signatureBoard {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 0.5rem 0;
    img {
      width: 200px;
    }
  }
  .children {
    max-width: max-content !important;
  }
  .deliverySlipContainer {
    width: 100%;
    padding: 1rem 0;
    z-index: -1;
    overflow-x: auto;
    th,
    td {
      text-align: start;
      padding: 0.2rem 0.5rem;
      background-color: white !important;
    }
    .deliverySlip {
      background-color: #f6f6f6;
      box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
      padding: 1rem 0.9rem;
      margin: 0 0.9rem;
      .tableBody {
        border: 4px solid black;
        .checkBox > div {
          align-items: center;
        }
        .mealType > th {
          padding: 0 0.5rem;
          div {
            margin: 0.2rem 0 !important;
          }
        }

        .mealDelivered {
          border-bottom: 3px solid black;
        }

        .mealChecks > th,
        .mealChecks2 > th {
          border-right: 3px solid black;
        }

        .mealCheckData > td {
          border-bottom: 3px solid black;
          border-right: 3px solid black;
        }

        .mealCheckData {
          td {
            &:nth-child(3) {
              border-right: 0;
            }
          }
        }

        .milkTypeAmount > th,
        .milkTypeAmount > td {
          border-right: 2px solid black;
        }

        .fatFree > th,
        .fatFree > td {
          border-right: 2px solid black;
        }
        .lowFat > th,
        .lowFat > td {
          border-right: 2px solid black;
        }

        .wholeMilk > th,
        .wholeMilk > td {
          border-right: 2px solid black;
        }
        .wholeMilk {
          border-bottom: 3px solid black;
        }

        .DeliveryValidity,
        .ReceiptValidity {
          border: 3px solid black;
        }

        .DeliveryValidity > th,
        .DeliveryValidity > td {
          border-right: 3px solid black;
        }
        .ReceiptValidity > th,
        .ReceiptValidity > td {
          border-right: 3px solid black;
        }
      }
    }
  }

  .text-center {
    text-align: center;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-spacing: 0;
    border-collapse: collapse;
  }
}
