.generalBtn {
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
  border: 0;
  background-color: #ffff;
  border: 1px solid #cecece;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
}
