#home {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  min-height: 85vh;

  .graphicsSection {
    display: flex;
    align-items: center;
    justify-content: center;

    .playGroundImage {
      width: max-content;
    }
  }

  .navLinks {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 6rem;
    .link {
      text-decoration: none;
      color: grey;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.08);
      padding: 0.4rem 1.2rem;
      border-radius: 0.7rem;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: #16c233;
      }

      .linkTitle {
        padding-left: 0.5rem;
      }
    }
  }
}
