#vendorDashboard {
  .container {
    margin: 1rem auto;
  }

  .tableContainer {
    width: 100%;
    table {
      width: 100%;
    }
  }
  th,
  td {
    text-align: center;
    background-color: #fff;
  }
}
