#container {
  max-width: 1280px;
  margin: 0 auto;
}

.wave {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: -2;
}
