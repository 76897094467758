#CnLabelPage {
  .container {
    margin: 1rem auto;
  }
  .cnlabelContainer {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;

    .cnlabelHeader {
      overflow: hidden;
      display: flex;
      padding: 0.5rem 0;
      align-items: center;
      justify-content: flex-end;
      background-color: #505050;
      border-radius: 10px 10px 0px 0px;

      input[type="file"] {
        position: absolute;
        z-index: -1;
        top: 10px;
        right: 8px;
      }

      .button-wrap {
        position: relative;
        margin-right: 0.5rem;
      }

      .button {
        display: inline-block;
        padding: 0.5rem 1rem;
        cursor: pointer;
        border-radius: 5px;
        background-color: #3bb34f;
        font-size: 1rem;
        font-weight: 600;
        color: rgb(245, 245, 245);
      }
    }
    .cnlabelContent {
      max-height: 65vh;
      padding: 0.5rem 1rem;
      box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      border-radius: 0 0 10px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow-y: auto;

      .fileContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin: 0.5rem;
        padding: 0.5rem 0;
        width: 160px;
        height: min-content;
        position: relative;

        .download,
        .delete {
          position: absolute;

          cursor: pointer;
          display: none;
        }

        .download {
          top: 5px;
          right: 10px;
          &:hover {
            color: rgb(41, 116, 41);
          }
        }
        .delete {
          top: 5px;
          left: 10px;
          &:hover {
            color: rgb(167, 40, 40);
          }
        }

        p {
          margin: 0;
        }
        &:hover {
          background-color: rgb(224, 224, 224);
          .download,
          .delete {
            display: flex;
          }
        }
        img {
          width: 65px;
        }
      }
    }
  }
}
