.siteDashboard {
  display: flex;
  flex-direction: column;
  align-items: center;

  .siteHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 12vh;
    position: relative;
    .backwardLink {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.7;
      }
    }
    .title {
      font-size: 2rem;
      color: #636363;
      font-weight: 400;
    }
  }
  .dashboardMenus {
    width: 100%;
    background-color: #0000001a;
    display: flex;
    align-items: center;
    .links {
      display: grid;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      padding: 1rem 0;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #b6b6b6;
        padding: 0.5rem 0;
        font-size: 1.2rem;
        color: #7a7a7a;
        transition: color 0.2s ease-in-out;

        span {
          padding-left: 1rem;
        }
        &:last-child {
          border: none;
        }

        &:hover {
          color: #00d925;
        }
      }
    }
  }
}
