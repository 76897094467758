.mealCountListContainer {
  width: 100%;
  padding: 1rem 0;
  z-index: -1;
  .mealCountList {
    background-color: #f6f6f6;
    box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
    padding: 1rem 0.9rem;
    margin: 0 0.9rem;
    display: flex;
    flex-direction: column;

    .formTitle {
      margin: 0.2rem 0;
    }
    .formRules {
      font-size: 0.85rem;
      font-weight: 400;
      align-self: center;
    }
    .table {
      border: 3px solid black;
      width: 100%;
      .tableBody {
        .mealCountHeader {
          .mealDetails > div {
            display: flex;
            align-items: center;
            padding: 0 0.5rem;
          }

          .mealDetails {
            padding: 0.5rem 0;
            border: 3px solid black;

            b {
              font-size: 0.9rem;
              white-space: nowrap;
            }
            .date {
              display: flex;
              align-items: center;
            }

            .date > div {
              display: flex;
              align-items: center;
              margin-left: 0.5rem;
            }
            .week {
              margin: 0;
              padding: 0.5rem;
              display: flex;
              align-items: center;
              div {
                margin-top: 0 !important;
                margin: 0 0.5rem;
              }
            }
            .classroom {
              div {
                padding: 0.5rem;
                margin: 0 !important;
                margin-left: 0.5rem;
              }
            }
            .teacherName {
              padding: 0.5rem;
              margin: 0;
              text-align: left;
            }
            .siteName {
              padding: 0.5rem;
              margin: 0;
              text-align: left;
            }
            .childName {
              border-top: 1px solid black;
              padding: 0.5rem;
              font-weight: 600;
            }
          }
        }
        .mealCountHeader > th {
          text-align: center;
          border: 3px solid black;
          border-bottom: 0;
        }
        .mealCountHeader2 > th {
          padding: 0;
          font-size: 0.7rem;
          font-weight: 300;
          border: 0;
          border-bottom: 3px solid black;
          color: #fff;
          background-color: black;

          p {
            white-space: nowrap;
            padding: 0.5rem 0rem;
          }
        }
        .mealCountHeader2 {
          .breakfast {
            background-color: #d03161;
            padding: 1.8rem 0;
          }
          .AMSnack {
            background-color: #dce4e5;
            color: #000;
          }
          .Lunch {
            background-color: #bfd8d1;
            color: #000;
          }
          .PMSnack {
            background-color: #178a94;
          }
          .Supper {
            background-color: #2b374b;
          }
          .EveningSnack {
            border-right: 3px solid black;
            background-color: #54696a;
          }
        }
        .dailyMealCount {
          color: #fff;
          .breakfast {
            background-color: #d03161;
          }
          .AMSnack {
            background-color: #dce4e5;
            color: #000;
          }
          .Lunch {
            background-color: #bfd8d1;
            color: #000;
          }
          .PMSnack {
            background-color: #178a94;
          }
          .Supper {
            background-color: #2b374b;
          }
          .EveningSnack {
            background-color: #54696a;
          }
        }

        .mealData > td {
          text-align: center;
          cursor: pointer;
          &:first-child {
            text-align: left;
            cursor: auto;
          }
        }

        .dailyMealCount {
          border: 3px solid black;
          th {
            border: 3px solid black;
            color: #000;
          }
          .EveningSnack {
            border-right: 3px solid black;
          }
        }

        .dailyMealCount > td {
          text-align: center;
          border: 0;
        }

        .dummy {
          height: 30px;
        }
      }
      .tableFooter {
        vertical-align: baseline;
        th {
          border: 3px solid black;
        }
        td {
          border: 3px solid black;
          vertical-align: middle;
        }
        td > div {
          display: flex;
          align-items: center;
          flex-direction: column;
          span {
            color: red;
            img {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

.text-center {
  text-align: center;
}

table,
th,
td {
  border: 1px solid black;
  border-spacing: 0;
  border-collapse: collapse;
}

th,
td {
  text-align: start;
  padding: 0.2rem 0.5rem;
  overflow: hidden;
}

.rotateText {
  transform: rotate(270deg);
  margin: 0;
}
