.formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  .createInvoiceForm {
    min-width: 1000px;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.15);
    padding: 0.5rem;
    border-radius: 10px;
    background: #ffffff85;
    button {
      margin-top: 0.5rem;
      width: 200px;
    }
    .itemContainer {
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        div {
          width: 33%;
        }
      }
    }
  }

  .createStudentForm > div {
    margin-top: 0.6rem;
  }
}
