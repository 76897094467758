@media print {
  header {
    display: none;
  }
  button {
    display: none;
  }
  .dashboardHeader {
    display: none !important;
  }
}
#montiorReviewForm {
  table {
    width: 100% !important;
  }
  .textCenter {
    text-align: center;
    margin: 0.2rem 0;
  }

  .rowFlex {
    display: flex;

    align-items: center;
    justify-content: space-around;
    margin: 0.5rem 0;
  }
  .container {
    margin: 1rem auto;
  }
  .monitorReviewContainer {
    width: 100%;
    padding: 1rem 0;
    z-index: -1;
    overflow-x: auto;
    .monitorReview {
      background-color: #f6f6f6;
      box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
      padding: 1rem 0.9rem;
      margin: 0 0.9rem;
      .formHeader {
        .description {
          text-align: justify;
          font-size: 0.9rem;
        }
        .chartTable {
          margin: 0.5rem 0;
          .chartDesc {
            text-align: justify;
            font-size: 0.9rem;
          }
          table {
            margin: 0 auto;
            width: 90%;
          }
        }
      }
      .sponsorTable {
        margin-top: 1re;

        table {
          margin: 0 auto;

          .reviewNumber > div {
            margin: 0.5rem 0;
          }
          .reviewAveragingOption > div {
            margin: 0.5rem 0;
            .inputField {
              margin: 0 0.2rem !important;
            }
          }
        }
      }
      .mealService {
        margin: 3rem 0;
        table {
          width: 100%;
          margin: 0 auto;
        }
        margin-bottom: 0;
      }
      .mealService2 {
        margin: 1rem 0;
        table {
          margin: 0 auto;
        }
      }
      .mealCount {
        margin: 2rem 0;
        table {
          margin: 0 auto;
          tr > td {
            height: 20px;
          }
        }
      }
      .dayRecolation {
        margin: 2rem 0;
        table {
          margin: 0 auto;
          tr > td {
            height: 20px;
          }
        }
      }
      .mealServiceMenus {
        margin: 2rem 0;
        table {
          margin: 0 auto;
          tr > td {
            height: 20px;
          }
        }
      }
      .sanitation {
        margin: 2rem 0;
        table {
          margin: 0 auto;
          tr > td {
            height: 20px;
          }
        }
      }
      .civilrights {
        margin: 2rem 0;
        table {
          margin: 0 auto;
          tr > td {
            height: 20px;
          }
        }
      }
      .recordKeeping {
        margin: 2rem 0;
        table {
          margin: 0 auto;
          tr > td {
            height: 20px;
          }
        }
      }
      .findingsSummary {
        margin: 2rem 0;
        table {
          margin: 0 auto;
          tr > td {
            height: 20px;
          }
        }
      }
      .findingAndCorrectiveAction {
        margin: 2rem 0;
        table {
          margin: 0 auto;
          tr > td {
            height: 20px;
          }
        }
      }
      .tableBody {
        border: 4px solid black;
      }
    }
  }
}
