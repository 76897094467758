#authentication {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 86vh;
  position: relative;
  .backwardLink {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.7;
    }
  }
  .loginForm {
    background-color: rgb(248, 248, 248);
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px -1px 11px 2px rgba(0, 0, 0, 0.12);
    .loginTitle {
      text-align: center;
      padding: 0;
      margin: 0;
    }
    form > div {
      margin: 0.5rem 0;
    }
  }
}
